<template>
<div class="content">
    <b-row>
        <b-col cols="1" style="margin-left: 1rem; " @click="Kembali"> <img src="@/assets/assets/BackImage.png" alt="" ></b-col>
        <b-col><h2 style="margin-right: 4rem; font-family: Oswald">Riwayat</h2></b-col>
    </b-row>
  <b-card no-body id="bcard">
    <b-tabs card align="center" active-nav-item-class="toActive" nav-class="backgroundCol" justified>
      <b-tab title="Voucher & Gift" active title-link-class="tab" id="judulTab" >
          <h3 v-if="!datasVoucher.length">Tidak Ada Riwayat Voucher/Gift</h3>
          <div class="card" v-for="(item,i) in datasVoucher" :key="i.id" >
            <b-row>
                <b-col>
                    <b-row><img :src=item.image alt="" style="max-height: 250px; margin-bottom: 1rem;  object-fit:fill; "></b-row>
                    
                    <b-row>
                        <h5 class="judul">{{item.title}}</h5>
                    </b-row>
                    <b-row style="margin-top: 0.5rem;">
                        <b-col>
                            <div class="card-block" >
                                <h6>{{item.date.substring(0,16)}}</h6>
                             </div>
                        </b-col>
                        <b-col>
                            <h6 class="status">{{item.status}}</h6>
                        </b-col>
                    </b-row>
                </b-col> 
            </b-row>
          </div>
      </b-tab>

      <!-- RIWAYAT BELANJA -->
      <b-tab title="Belanja" title-link-class="tab" id="judulTab">
          <h3 v-if="eror">Tidak Ada Riwayat Belanja</h3>
            <div class="card" v-for="(item,i) in datasBelanja" :key="i.id" >
               
                <b-row id="belanja">
                    <b-col style="margin-top:1rem;">
                        <ul class="poin">
                            <li class="jenisP" v-if="item.tipe_transaksi === 'T'"><p >Tunai</p></li>
                            <li class="jenisP" v-if="item.tipe_transaksi === 'R'"><p >Retur</p></li>
                            <li><p style="color: #707070">{{item.tanggal}}</p></li>
                        </ul>
                    </b-col>
                </b-row>
                <b-row id="belanja">
                    <b-col><p style="margin-left: 2rem; margin-top:-0.5rem" class="judul">{{item.nomor_nota}}</p></b-col>
                    <b-col>
                        <b-button style="float: right; margin-right: 2rem;" @click="sendNota(item)">
                            Nota
                        </b-button>
                        <b-modal v-model="modalShow" id="modalNota">
                               
                            <div v-html="selectedNota.detail">

                            </div>
                        </b-modal>
                      
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <ul class="poin">
                            <li class="namaT" v-if="item.department_id === '01'"><p>Bimbo Delanggu</p></li>
                            <li class="namaT" v-if="item.department_id === '02'"><p>Granada Delanggu</p></li>
                            <li class="namaT" v-if="item.department_id === '03'"><p>Amigo Klaten</p></li>
                            <li class="namaT" v-if="item.department_id === '04'"><p>Dinasti Prambanan</p></li>
                            <li class="namaT" v-if="item.department_id === '05'"><p>Amigo Pedan</p></li>
                            <li class="namaT" v-if="item.department_id === '06'"><p>Amigo Sukoharjo</p></li>
                            <li class="namaT" v-if="item.department_id === '07'"><p>Amigo Boyolali</p></li>
                            <li class="namaT" v-if="item.department_id === '08'"><p>Amigo Wonosari</p></li>
                            <li class="namaT" v-if="item.department_id === '09'"><p>Amigo Karanganyar</p></li>
                            <li class="totalB" style="color: #707070;">Rp. {{formatPrice(item.nominal_belanja)}}</li>
                            <li class="totalB" style="margin-right: 0.5rem; margin-top: 0rem"><img src="@/assets/assets/CoinBelanja.png"></li>
                        </ul>
                    </b-col>
                </b-row>
            </div>   
      </b-tab>
    
      <!-- POIN MASUK -->
      <b-tab title="Poin Masuk" title-link-class="tab" id="judulTab">
          <h3 v-if="!datas.length">Tidak Ada Riwayat Poin</h3>
             <div class="card" v-for="(item,i) in datas.slice(0,20)" :key="i.id">
                  
                <b-row>
                    <b-col style="margin-top:0.5rem;">
                        <div style="text-align: left; margin-left: 1rem">
                            <p class="card-text" id="tanggalPoin">{{item.tanggal}}</p>
                            <h5 class="card-title">Poin Masuk</h5>
                        </div>
                    </b-col>
                    <b-col>
                        <ul class="poin">
                            <li class="totalBmasuk" style="margin-top: 1rem">{{item.poin_bertambah}}</li>
                            <li class="totalBmasuk" style="margin-right: 0.4rem; "><img style=" margin-bottom: 0px; margin-top: 1rem;padding-bottom: 0px;" src="@/assets/assets/PoinMasuk.png"></li>
                        </ul>
                    </b-col>
                </b-row>
            </div>
      </b-tab>

      <!-- POIN KELUAR -->
      <b-tab title="Poin Keluar" title-link-class="tab" id="judulTab">
          <h3 v-if="!datasKeluar.length">Tidak Ada Riwayat Poin</h3>
            <div id="reverse" class="card" v-for="(item,i) in datasKeluar.slice(0,20)" :key="i.id">
                <b-row v-if="item.tanggal_kadaluwarsa == null">
                    <b-col style="margin-top: 0.5rem;">
                        <div style="text-align: left; margin-left: 1rem">
                            <p class="card-text" id="tanggalPoin">{{item.tanggal}}</p>
                            <h5 class="card-title">Poin Keluar</h5>
                        </div>
                    </b-col>
                    <b-col>
                        <ul class="poin">
                            <li class="totalB" style="margin-top: 1rem">{{item.poin_berkurang}}</li>
                            <li class="totalB" style="margin-right: 0.4rem; "><img style=" margin-bottom: 0px; margin-top: 1rem;padding-bottom: 0px;" src="@/assets/assets/PoinKeluar.png"></li>
                        </ul>
                    </b-col>
                </b-row>
                <b-row v-if="item.tanggal_kadaluwarsa != null">
                    <b-col style="margin-top: 0.5rem;">
                        <div style="text-align: left; margin-left: 1rem">
                            <p class="card-text" id="tanggalPoin">{{item.tanggal}}</p>
                            <h5 class="card-title">Poin kadaluwarsa</h5>
                        </div>
                    </b-col>
                    <b-col>
                        <ul class="poin">
                            <li class="totalB" style="margin-top: 1rem">{{item.poin_aktif}}</li>
                            <li class="totalB" style="margin-right: 0.4rem; "><img style=" margin-bottom: 0px; margin-top: 1rem;padding-bottom: 0px;" src="@/assets/assets/PoinKeluar.png"></li>
                        </ul>
                    </b-col>
                </b-row>
            </div>
      </b-tab>
    </b-tabs>
  </b-card>
</div>
</template>

<script>
import axios from "axios"
import moment from 'moment'
import dotenv from "dotenv"
import firebase from 'firebase/app'
import 'firebase/auth'

dotenv.config()

export default {
    name: "Riwayat",
   
    data(){
       
        return{
            datas : [],
            datasKeluar: [],
            datasVoucher: [],
            datasBelanja: [],
            token: '',
            eror: false,
            selectedNota: '',
            modalShow: false,
            notaHtml: ''
        }
    },
    created(){
        if(localStorage.getItem('apiData') && localStorage.getItem('firebaseUID') && localStorage.getItem('tokenDevice') && localStorage.getItem('apiDataToken')){
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    if(firebase.auth().currentUser.emailVerified==true){
                        this.$router.replace({name : "Riwayat"}).then(() => {
                            this.$toast.open({
                                message: 'Selamat Datang Kembali !',
                                type: 'success',
                                position: 'top'
                            });
                        }).catch(()=>{})
                        this.getPoinMasuk();
                        this.getPoinKeluar();
                        this.getVoucherGift();
                        this.getBelanja();
                    }
                } else {
                    firebase.auth().signOut()
                        .then(() => {
                            localStorage.clear();
                            this.$router.replace("/").then(() => { 
                                this.$toast.open({
                                    message: 'Harap Login Kembali !',
                                    type: 'error',
                                    position: 'top'
                                });
                                this.$router.go() })
                        }).catch((err) => {
                            console.log(err);
                    });
                }
            });
        } else {
            firebase.auth().signOut()
                .then(() => {
                    localStorage.clear();
                    this.$router.replace("/").then(() => { 
                        this.$toast.open({
                            message: 'Harap Login Kembali !',
                            type: 'error',
                            position: 'top'
                        });
                        this.$router.go() })
                }).catch((err) => {
                    console.log(err);
            });
        }
    },
    methods :{
        sendNota(item){
            this.modalShow =true
            this.selectedNota = item
        },
        formatPrice(value) {
        let val = value.toString()
        let sisa = val.length %3
        let rupiah = val.substr(0,sisa)
        let ribuan = val.substr(sisa).match(/\d{3}/g);
        if(ribuan){
            let separator = sisa ? '.':'';
             return rupiah += separator + ribuan.join('.');
        }
        },
        async getPoinMasuk(){
            let userData = JSON.parse(localStorage.getItem('apiData'));
            try{
                const response = await axios.get(process.env.VUE_APP_poinMasuk, { params: {pelanggan_id: userData.data.alias}});
                this.datas = response.data;
                this.changeDate(this.datas, "masuk")
                // console.log(this.datas)
            }
            catch(err){
                console.log(err);
            }
        },
        async getPoinKeluar(){
            let userDataKeluar = JSON.parse(localStorage.getItem("apiData"));
            try{
                const responseKeluar = await axios.get(process.env.VUE_APP_poinKeluar, { params: {pelanggan_id: userDataKeluar.data.alias}});
                let tempKeluar = responseKeluar.data;
                const responseKadaluwarsa = await axios.get(process.env.VUE_APP_poinKadaluwarsa, { params: {pelanggan_id: userDataKeluar.data.alias}})
                let tempKadaluwarsa = responseKadaluwarsa.data
                tempKeluar = tempKeluar.slice().reverse()
                this.datasKeluar = this.datasKeluar.concat(tempKeluar)
                this.datasKeluar = this.datasKeluar.concat(tempKadaluwarsa)
                this.generalize(this.datasKeluar)
                this.changeDate(this.datasKeluar, "keluar")
            }
            catch(err){
                console.log(err);
            }
        },
        async getBelanja(){
            let userDataBelanja = JSON.parse(localStorage.getItem("apiData"));
            try{
                const response = await axios.get(process.env.VUE_APP_belanja, { params: {pelanggan_id: userDataBelanja.data.alias, page: 1}});
                this.datasBelanja = response.data.data;
                console.log(this.datasBelanja);
                for(let i = 0;i<this.datasBelanja.length;i++){
                    if(this.datasBelanja[i].detail != null){
                        var nota = JSON.parse(this.datasBelanja[i].detail)
                        var keys = Object.keys(nota)
                        this.datasBelanja[i].detail= nota[keys[1]]

                    }
                console.log(this.datasBelanja[i].detail);    
                }

                
                // eslint-disable-next-line no-unused-vars
               
                
            
                // for(let i = 0; i<this.datasBelanja.length;i++){
                //     console.log(this.datasBelanja[i].detail[3]);
                // }
                // this.notaHtml = JSON.parse(JSON.stringify(response.data.data.detail))
                // console.log(this.notaHtml);
                this.tamp = response.status;
                this.changeDate(this.datasBelanja, "nota")
            }
            catch(err){
                this.eror = true;
            }
        },
        async getVoucherGift(){
            let userDataVoucher = JSON.parse(localStorage.getItem("apiData"));
            let userDataToken = JSON.parse(localStorage.getItem("apiDataToken"));
            try{
                const response = await axios.get(`${process.env.VUE_APP_voucherGift}${userDataVoucher.data.alias}/limit/20`,{ headers: {"Authorization" : `Bearer ${userDataToken.token}`} });
                this.datasVoucher = response.data.data;
                this.changeDateVoucher(this.datasVoucher)
            }
            catch(err){
                console.log(err);
            }
        },
        Kembali(){
            this.$router.replace('Homepage')
        },
        changeDate(array, type){
            if(type == "keluar") {
                for(let i = 0; i<array.length;i++){
                    moment.locale('id')
                    array[i].tanggal = moment(array[i].tanggal).format('LL')
                    if(array[i].tanggal_kadaluwarsa) {
                        array[i].tanggal = moment(array[i].tanggal_kadaluwarsa).format('LL')
                    }
                }
            } else {
                for(let i = 0; i<array.length;i++){
                    moment.locale('id')
                    array[i].tanggal = moment(array[i].tanggal).format('LL')
                }
            }
        },
        generalize(array){
            for(let i = 0; i<array.length;i++){
                if(array[i].tanggal_kadaluwarsa) {
                    array[i].tanggal = array[i].tanggal_kadaluwarsa
                }
            }
            array = array.sort(function(a,b){
                let x = a.tanggal
                let y = b.tanggal
                if(x<y){return 1}
                if(x>y){return -1}
                return 0
            })
        },
        changeDateVoucher(array){
            for(let i = 0; i<array.length;i++){
                moment.locale('id')
                array[i].date = moment(array[i].date).utc().format('LL')
            }
        },
    }
}

</script>

<style >
.css-fix {
  white-space: pre-line;
}
    .card{
        margin-bottom: 0.5rem;
    }
    .content{
        width: 100%;
        margin-top: 1rem;
        
    }
    .judul{
        color: #007476;
    }
    .status{
        color: #C12D31;
        
    }
    .poin{
        list-style: none;
        color: #707070;
        font-family: Oswald;
        
    }
    .ikonPoin{
        float: right;
        margin-left: 5px;
    }
    #tanggalPoin{
        margin-bottom: 0px;
    }
    .tab {
        color: #707070 !important;
        margin-bottom: 1rem;
    }
    .toActive {
        color: #C12D31 !important;
        border: none !important;
        border-bottom: 2px solid #C12D31 !important;
    }
    .backgroundCol {
        background-color: #FFF !important;
    }
    .tabs>.card-header {
        padding: 0;
    }
    #reverse{
        display: flex;
        flex-direction: column-reverse;
    }
    #bcard {
        border-top: none;
    }
    #biaya{
        margin-top: 2rem;
    }
    #belanja{
        text-align: left;
    }
    .jenisP{
        color: #C12D31;
        float: right;
        margin-right: 2rem;
    }
    .namaT{
        float: left;
        
    }
    .totalB{
        float: right;
        margin-right: 2rem;
        color: #C12D31;
    }
    .totalBmasuk{
        float: right;
        margin-right: 2rem;
        color: #007476;
    }
    #judulTab{
        font-family: Oswald;
       
    }
 
</style>