<template>
    <div class="container forOverlay content">
        <b-row>
            <b-col cols="1" class="kembali" @click="back"> <img src="@/assets/assets/BackImage.png" alt="" style="width: 1.5rem"></b-col>
            <!-- <b-col><h2 style="margin-right: 4rem; font-family: Oswald">Detail Voucher/Gift</h2></b-col> -->
        </b-row>
        <div  v-for="item in items" :key="item.kode">
            <div v-if="item.kode == kodeVoucher">
                <img :src="item.foto" alt="" class="foto">
                <div class="description">
                    <!-- Gift Title -->
                    <!-- <p class="headers title">Judul</p> -->
                    <b-row>
                        <p class="headers">Judul test</p>
                        <p>{{item.judul}}</p>
                    </b-row>
                    <b-row>
                        <h3 class="headers">Deskripsi</h3>
                    </b-row>
                    <b-row>
                        <p v-html="item.keterangan" style="text-align: left"> </p>
                    </b-row>
                    <b-row>
                        <b-col>
                            <button v-if="state" class="btnPakai" @click="useVoucher(item.kode)" v-b-modal="'modal-center'">
                        <h4>Pakai Promo</h4>
                        </button>
                        </b-col>
                        <b-modal  id="modal-center" centered size="lg" hide-footer hide-header>
                            <div class="container">
                                <b-row class="judulBarcode">
                                    <h3>Tunjukan Barcode untuk di scan</h3>
                                </b-row>
                                <b-row>
                                    <div class="barcodes">
                                        <barcode v-bind:value="barcodeValue"
                                        font-size="20rem"
                                        height="80%"
                                        format="CODE128" />
                                    </div>
                                </b-row>
                                <b-row>
                                    <button class="btnTutup" @click="$bvModal.hide('modal-center')">Tutup</button>
                                </b-row>
                            </div>
                        </b-modal>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// const date = new Date();
import VueBarcode from 'vue-barcode'
export default {
    name: "DetailVoucher",
    props: ['items', 'kodeVoucher', 'state'],
    components: {'barcode' : VueBarcode},
    data() {
        return {
            barcodeValue : "",
        }
    },
    methods: {
        useVoucher(voucherCode) {
            let localData = JSON.parse(localStorage.getItem("apiData"))
            this.barcodeValue = voucherCode + localData.data.alias
            if(this.barcodeValue.length < 14) {
                for(let i=0; i<14-this.barcodeValue.length; i++) this.barcodeValue += '#'
            }
        },
        back() {
            this.$router.replace('Vouchers')
        }
    }
}
</script>

<style scoped>
.foto{
    width: 100%;
    margin-top: -5rem;
    z-index: -1;
    align-items: center;
}
.kembali{
    font-family: Oswald;
    z-index: 1;
    background-color: white;
    width: 3rem;
    border-radius: 1rem;
}
.headers {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    text-align: left;
    font-family: oswald;
}
.sk {
    color: grey;
    font-weight: bold;
}
.text {
    text-align: justify;
}
img {
    width: 100%;
}
.description {
    text-align: left;
}
.btnPakai{
    font-family: Oswald;
    background-color: #C12D31;
    border-radius: 2rem;
    border: none;
    width: 90%;
    align-items: center;
    color: white;
    position: relative;
    height: 3rem;
}
.btnTutup{
    font-family: Oswald;
    background-color: #C12D31;
    border-radius: 2rem;
    border: none;
    width: 100%;
    align-items: center;
    color: white;
    height: 2rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}
.vue-barcode-element{
    width: 100%;
}
.judulBarcode{
    font-family: Oswald;
    text-align: center;
    font-weight: bold;
}
.barcodes {
    text-align: center;
    font-family: Oswald;
}
</style>