<template>
<div class="content">
    <b-row>
        <b-col cols="1" style="margin-left: 1rem; font-family: Oswald" @click="Kembali"> <img src="@/assets/assets/BackImage.png" alt=""></b-col>
        <b-col><h2 style="margin-right: 4rem">Promo</h2></b-col>
    </b-row>
    <b-tabs>
      <!-- List Toko -->
         <div v-for="(item,i) in DataListPromo" :key="i.id" style="font-family: Oswald">
                <b-row class="listPromo">
                        <img @click="sendData(item.kode)" class="fotoList" :src="item.foto_thumbnail">
                </b-row>
            </div>
    </b-tabs>
</div>
</template>

<script>
import router from './router'
import axios from "axios"
import moment from 'moment'
import dotenv from "dotenv"
import firebase from 'firebase/app'
import 'firebase/auth'
dotenv.config()

export default{
    name: "ListPromo",
    created(){
        if(localStorage.getItem('apiData') && localStorage.getItem('firebaseUID') && localStorage.getItem('tokenDevice') && localStorage.getItem('apiDataToken')){
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    if(firebase.auth().currentUser.emailVerified==true){
                        this.$router.replace({name : "ListPromo"}).then(() => {
                            this.$toast.open({
                                message: 'Selamat Datang Kembali !',
                                type: 'success',
                                position: 'top'
                            });
                        }).catch(()=>{})
                        this.getListPromo()
                    }
                } else {
                    firebase.auth().signOut()
                        .then(() => {
                            localStorage.clear();
                            this.$router.replace("/").then(() => { 
                                this.$toast.open({
                                    message: 'Harap Login Kembali !',
                                    type: 'error',
                                    position: 'top'
                                });
                                this.$router.go() })
                        }).catch((err) => {
                            console.log(err);
                    });
                }
            });
        } else {
            firebase.auth().signOut()
                .then(() => {
                    localStorage.clear();
                    this.$router.replace("/").then(() => { 
                        this.$toast.open({
                            message: 'Harap Login Kembali !',
                            type: 'error',
                            position: 'top'
                        });
                        this.$router.go() })
                }).catch((err) => {
                    console.log(err);
            });
        }
    },
    methods:{
        async getListPromo(){
            let userDataToken = JSON.parse(localStorage.getItem("apiDataToken"));
            try{
                const response = await axios.get(process.env.VUE_APP_listPromo,{headers: {"Authorization": `Bearer ${userDataToken.token}`}})
                this.DataListPromo = response.data.data
                for(let i=0; i<this.DataListPromo.length; i++) {
                    moment.locale("id")
                    this.DataListPromo[i].periode_awal = moment(this.DataListPromo[i].periode_awal).format('LL')
                    this.DataListPromo[i].periode_akhir = moment(this.DataListPromo[i].periode_akhir).format('LL')
                }
                console.log(this.DataListPromo)
            }
            catch(err){
                console.log(err)
            }
        },
        Kembali(){
            this.$router.replace('Homepage')
        },
        sendData(idPromo){
            router.push({
                name:'DetailPromo',
                params:{
                    items: this.DataListPromo,
                    kodeId: idPromo,
                    state: true,
                    from: 'list'
                }
            })
        }
    
    },
    data(){
        return{
            DataListPromo: [],
        }
        
    }
}
</script>

<style scoped>
    .fotoList{
        width: 100%;
        height: 14rem;
        border-radius: 2rem;
        
    }
    .listPromo{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
</style>