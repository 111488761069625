<template>
    <div v-if="localdata" class="container">
        <b-row>
            <b-col @click="routeOverlay">
                <img src="@/assets/assets/person.png" alt="" class="iconProfile" style="width: 2rem; height: 2.5rem">
                
            </b-col>
            <b-col cols="8">
                <h2 class="judulHome">Amigo Member</h2>
            </b-col>
            <b-col>
            </b-col>
        </b-row>
      
        <div id="cardcontainer">
            <h3>{{localdata.nama}}</h3>
            <ul class="listlogopoin">
                <li class="listitems" id="listitem1"><img src="../assets//assets/dancing_logo.png" alt="" class="logoimg"></li>
                <li class="listitems" id="listitem2">{{localdata.poin}}</li>
                <li class="listitems" id="listitem3"><b-button class="refreshButton" @click="refreshPoint"><b-icon icon="arrow-clockwise"></b-icon></b-button></li>
            </ul>
            <div class="barcodes" v-b-modal="'modal-center'">
                <div class="containerBarcode" style="margin-bottom: 1rem;">
                    <barcode v-bind:value="localdata.alias"
                    font-size="13rem"
                    height="70%"
                    format="CODE128" />
                </div>
                <b-modal id="modal-center" centered size="sm" hide-footer hide-header>
                        <div class="containerBarcode">
                            <b-row style="text-align: center">
                                    <barcode v-bind:value="localdata.alias"
                                        font-size="25rem"
                                        height="80%"
                                        width=2
                                        format="CODE128" />
                            </b-row>
                            <b-row>
                                <button class="btnTutup" @click="$bvModal.hide('modal-center')">Tutup</button>
                            </b-row>
                        </div>
                </b-modal>
            </div>
        </div>

        <div class="tabscontainer">
            <b-row class="menuIcon">
                <b-col>
                    <div @click="routeVoucherGift">
                        <img class="sizeimage" src="@/assets/assets/VnG.png" alt="">
                        <p class="JudulIcon">Voucher & Gift</p>
                    </div>
                </b-col>
                <b-col>
                    <div @click="routeToko">
                        <img  class="sizeimage" src="@/assets/assets/iconToko.png" alt="">
                        <p class="JudulIcon">Toko</p>
                    </div>
                </b-col>
                <b-col>
                    <div @click="routeTukarPoin">
                        <img  class="sizeimage" src="@/assets/assets/monetization_on_black_24dp.png" alt="">
                        <p class="JudulIcon">Tukar Poin</p>
                    </div>
                </b-col>
                <b-col>
                    <div @click="routeRiwayat">
                        <img  class="sizeimage" src="@/assets/assets/iconRiwayat.png" alt="">
                        <p class="JudulIcon">Riwayat</p>
                    </div>
                </b-col>
            </b-row>
        </div>
      <InfoPromo />
  </div>
</template>

<script>
// import BarcodeGenerator from './BarcodeGenerator.vue'
// import Voucherngifts from './VoucherNGifts.vue'
import axios from 'axios'
import InfoPromo from './InfoPromo.vue'
import VueBarcode from 'vue-barcode'
import firebase from 'firebase/app'
import 'firebase/auth'
import dotenv from "dotenv"
dotenv.config()

export default {
    name: 'Homepage',
    components: {
        // BarcodeGenerator
        // Voucherngifts,
        'barcode':VueBarcode,
        InfoPromo
    },
    created() {
        
        //this.getToken();
        if(localStorage.getItem('apiData') && localStorage.getItem('firebaseUID') && localStorage.getItem('tokenDevice') && localStorage.getItem('apiDataToken')){
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    if(firebase.auth().currentUser.emailVerified==true){
                        this.$router.replace({name : "Homepage"}).then(() => {
                            this.$toast.open({
                                message: 'Selamat Datang Kembali !',
                                type: 'success',
                                position: 'top'
                            });
                        }).catch(()=>{})
                        this.loadLocalData();
                        this.getAuth();
                    }
                } else {
                    firebase.auth().signOut()
                        .then(() => {
                            localStorage.clear();
                            this.$router.replace("/").then(() => { 
                                this.$toast.open({
                                    message: 'Harap Login Kembali !',
                                    type: 'error',
                                    position: 'top'
                                });
                                this.$router.go() })
                        }).catch((err) => {
                            console.log(err);
                    });
                }
            });
        } else {
            firebase.auth().signOut()
                .then(() => {
                    localStorage.clear();
                    this.$router.replace("/").then(() => { 
                        this.$toast.open({
                            message: 'Harap Login Kembali !',
                            type: 'error',
                            position: 'top'
                        });
                        this.$router.go() })
                }).catch((err) => {
                    console.log(err);
            });
        }
    },
    data() {
        return {
            localdata: [],
            DataInfoPromo: [],
            auth: true
        }
    },
    methods: {
        loadLocalData () {
            let usrdata = JSON.parse(localStorage.getItem("apiData"))
            this.localdata = usrdata.data
            if(this.localdata.poin == null){
                this.localdata.poin = 0
            }
        },
        routeOverlay(){ 
            this.$router.replace('Overlay')
        },
        routeRiwayat(){ 
            this.$router.replace('Riwayat')
        },
        routeGifts() {
            this.$router.replace('Gifts')
        },
        routeToko() {
            this.$router.replace('Toko')
        },
        routeVoucherGift(){
            this.$router.replace('Vouchers')
        },
        getAuth() {
            localStorage.setItem("auth", this.auth)
        },
        routeTukarPoin() {
            this.$router.replace('TukarPoin')
        },
        async refreshPoint(){
            firebase.auth().onAuthStateChanged(
                async (user) => {
                    if(user){
                        const uid = user.uid
                        const token = JSON.parse(localStorage.getItem('apiDataToken'))
                        // eslint-disable-next-line no-unused-vars
                        const res = await axios.get(`${process.env.VUE_APP_refresh}${uid}`, { headers: {"Authorization" : `Bearer ${token.token}`} })
                        .then(res => {
                            localStorage.setItem("apiData", JSON.stringify(res))
                            window.location.reload()
                        })
                    }
                }
            )
        }
    }
}
</script>

<style>
*{
    font-family: Oswald;
}
.container {
    text-align: left;
}
#cardcontainer {
    background-image: url("../assets/assets/bg_member_card.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid grey;
    border-radius: 1.5rem;
    margin-top: 1rem;
    height: 17rem;
}
#cardcontainer > h3{
    margin-top: .5rem;
    padding: .5rem;
}
.barcodes {
    text-align: center;
}
.listlogopoin {
    list-style-type: none;
    padding: 0;
}
.listitems {
    display: inline-block;
    margin: 0;
    height: 40px;
}
.logoimg {
    width: 2rem;
}
#listitem1 {
    width: 15%;
    text-align: center;
    font-size: 1.3rem;
}
#listitem2 {
    width: fit-content;
    font-size: 1.3rem;
    color: darkred;
}
#listitem3 {
    margin-left: 2%;
    width: fit-content;
    text-align: center;
    font-size: 1.3rem;
    color: darkred;
}
.refreshButton{
    border: none !important;
    background: transparent !important;
    color: darkred !important;
    margin-bottom: 15%;

}
#cardcontainer {
    margin-bottom: 1rem;
}
.menuIcon{
    text-align: center;
    margin-bottom: -2rem;
    
}
.JudulIcon{
    /* margin-top: 0.75rem; */
    font-family: Oswald;
    margin-top: -1.5rem;
}
.judulHome{
    text-align: center;
    color: #C12D31;
    font-weight: bold;
    font-family: Oswald;
}
/* .iconProfile{
    height: 2rem;
    width: 1rem;
} */
/* .tabsContainer{
    font-family: Oswald;
} */

.LihatSemua{
    text-align: right;
    color: #007476;
}
.sizeimage{
    height: 2.25rem;
    margin-bottom: 1.5rem;
}
.btnTutup{
    font-family: Oswald;
    background-color: #C12D31;
    border-radius: 2rem;
    border: none;
    width: 100%;
    align-items: center;
    color: white;
    height: 2rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

</style>