<template>
    <div class="container forOverlay content">
        <b-row>
            <b-col cols="1" class="kembali" @click="back"> <img src="@/assets/assets/BackImage.png" alt="" style="width: 1.5rem"></b-col>
            <!-- <b-col><h2 style="margin-right: 4rem; font-family: Oswald">Detail Voucher/Gift</h2></b-col> -->
        </b-row>
        <div  v-for="item in items" :key="item.id">
            <div v-if="item.id == kodeVoucher">
                <img :src="item.vouchers.foto" alt="" class="foto" >
                <div class="description">
                    <!-- Gift Title -->
                    <!-- <p class="headers title">Judul</p> -->
                    <p v-if="item.vouchers.periode_awal&&item.vouchers.periode_akhir">Periode {{item.vouchers.periode_awal}} s.d {{item.tanggal_kadaluwarsa}}</p>
                    <p v-if="!item.vouchers.periode_awal&&!item.vouchers.periode_akhir">Periode {{item.updated_at}} s.d {{item.tanggal_kadaluwarsa}}</p>
                    <b-row>
                         <h3 class="desc">Judul</h3>
                    </b-row>
                    <b-row>
                       
                        <p style="text-align: left; font-size: 1.25rem">{{item.vouchers.judul}}</p>
                    </b-row>
                    <b-row>
                        <h3 class="desc">Deskripsi</h3>
                    </b-row>
                    <b-row>
                        <p v-html="item.vouchers.keterangan" style="text-align: left"> </p>
                    </b-row>
                    <b-row>
                <p v-html="item.keterangan" style="text-align: left"> </p>
            </b-row>
            <b-row>
                <h3 class="desc">Syarat & Ketentuan</h3>
                <div class="snk">
                    <ol>
                        <li>Menunjukan Amigo Member Apps</li>
                        <li>Minimal Transaksi Rp. {{item.minimal_belanja}}</li>
                        <!-- <li v-if="!item.minumal_belanja.length">Minimal Transaksi Rp. 0</li> -->
                    </ol>
                </div>
            </b-row>
            <b-row>
                <h3 class="desc">Cara Penukaran</h3>
                 <div class="snk">
                    <ol>
                        <li>Klik Tombol "Pakai Promo" dibagian bawah halaman ini</li>
                        <li>Tunjukan barcode untuk discan kasir</li>
                        <!-- <li v-if="!item.minumal_belanja.length">Minimal Transaksi Rp. 0</li> -->
                    </ol>
                </div>
            </b-row>
                    <b-row>
                        <b-col>
                            <button v-if="state" class="btnPakai" @click="useVoucher(item.kode)" v-b-modal="'modal-center'">
                        <h4>Pakai Promo</h4>
                        </button>
                        </b-col>
                        <b-modal  id="modal-center" centered size="lg" hide-footer hide-header>
                            <div class="container">
                                <b-row class="judulBarcode">
                                    <h3>Tunjukan Barcode untuk di scan</h3>
                                </b-row>
                                <b-row>
                                    <div class="barcodes">
                                        <barcode v-bind:value="barcodeValue"
                                        font-size="20rem"
                                        height="80%"
                                        format="CODE128" />
                                    </div>
                                </b-row>
                                <b-row>
                                    <button class="btnTutup" @click="$bvModal.hide('modal-center')">Tutup</button>
                                </b-row>
                            </div>
                        </b-modal>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// const date = new Date();
import VueBarcode from 'vue-barcode'
export default {
    name: "DetailVoucher",
    props: ['items', 'kodeVoucher', 'state'],
    components: {'barcode' : VueBarcode},
    data() {
        return {
            barcodeValue : "",
        }
    },
    methods: {
        useVoucher(voucherCode) {
            this.barcodeValue = voucherCode
        },
        back() {
            this.$router.replace('Vouchers')
        }
    }
}
</script>

<style scoped>
.foto{
    width: 100%;
    margin-top: -2rem;
    z-index: -1;
    align-items: center;
}
.kembali{
    font-family: Oswald;
    z-index: 1;
    background-color: white;
    width: 3rem;
    border-radius: 1rem;
}
.desc{
    text-align: left;
    font-weight: bold;
}
.headers {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-top: 1.2rem;
  
    text-align: left;
    font-family: oswald;
}
.sk {
    color: grey;
    font-weight: bold;
}
.text {
    text-align: justify;
}
.description {
    text-align: left;
}
.btnPakai{
    font-family: Oswald;
    background-color: #C12D31;
    border-radius: 2rem;
    border: none;
    width: 100%;
    align-items: center;
    color: white;
    position: relative;
    height: 3rem;
}
.btnTutup{
    font-family: Oswald;
    background-color: #C12D31;
    border-radius: 2rem;
    border: none;
    width: 100%;
    align-items: center;
    color: white;
    height: 2rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}
.vue-barcode-element{
    width: 100%;
}
.judulBarcode{
    font-family: Oswald;
    text-align: center;
    font-weight: bold;
}
.barcodes {
    text-align: center;
    font-family: Oswald;
}
.snk{
    float: left;
    color: #707070;
    font-size: 1rem;
    text-align: left;
}
</style>