import firebase from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyC1_wEUIiYMVs3l8FzY1ssj5pSRT3TkQJQ",
  authDomain: "amigo-member-apps.firebaseapp.com",
  databaseURL: "https://amigo-member-apps.firebaseio.com",
  projectId: "amigo-member-apps",
  storageBucket: "amigo-member-apps.appspot.com",
  messagingSenderId: "741031502844",
  appId: "1:741031502844:web:af8f7b393708291cc04879",
  measurementId: "G-BLV4R43H70"
};

firebase.initializeApp(firebaseConfig)

export default firebase