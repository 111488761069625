<template>
    <div style="position: relative;" class="container">
        <b-row >
            <b-col cols="1" class="kembali"  @click="Kembali()"> <img src="@/assets/assets/BackImage.png" alt="" class="iconKembali"></b-col>
            <!-- <b-col><h2 style="margin-right: 4rem">Detail Gift</h2></b-col> -->
        </b-row>
        <div>
        <!-- <div v-if="gift.id==kodeId"> -->
            <img :src="gift.foto" alt="" class="foto">
            <div class="description">
                <b-row>
                    <b-col>
                        <h2 style="text-align: left; padding-top: .5rem; padding-bottom: .5rem;"> {{gift.judul}} </h2>
                    </b-col>
                    <b-col id="setara">
                        <p style="color:#007476; font-weight:bold;"><img src="@/assets/assets/PoinMasuk.png" alt="" class="coinIcon"> {{gift.harga_poin}}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <p class="periode" style="font-size: 1.2rem; font-weight:600; color: grey">Periode {{gift.periode_awal}} s.d {{gift.periode_akhir}}</p>
                </b-row>
                <b-row>
                    <p class="periode" style="font-size: 1.2rem; font-weight: 600;">Stock: {{gift.stock}}</p>
                </b-row>
                <b-row>
                    <p class="periode" style="font-size: 1.2rem; font-weight: 600;">Maksimal Penukaran: {{gift.maksimal_tukar}}</p>
                </b-row>
                <b-row>
                    <p class="periode" style="font-size: 1.2rem; font-weight: 600;">Kesempatan Penukaran: {{gift.sisa_tukar}}</p>
                </b-row>
                <b-row>
                    <h3 class="desc">Deskripsi</h3>
                </b-row>
                <b-row style="text-align: left; font-size: 1.5rem; padding-top: .5rem;">
                    <p v-html="gift.keterangan" style="margin-bottom: .25rem;"></p>
                </b-row>
                <b-row>
                    <h3 class="desc">Cara Penukaran</h3>
                    <div class="snk">
                        <ol>
                            <li>Klik Tombol tukar dibagian bawah halaman ini</li>
                            <li>Klik tombol 'Tukar' jika ingin menukarkan voucher</li>
                            <li>Gift atau voucher yang telah ditukar dapat dilihat di halaman 'Gift & Voucher'</li>
                        </ol>
                    </div>
                </b-row>
                <b-row>
                    <b-col style="padding:.5rem">
                        <h5>Poin Saya: <img src="@/assets/assets/PoinKeluar.png" alt="" class="coinIcon"> <span style="color:red">{{userData.poin}}</span></h5>
                    </b-col>
                    <b-col>
                        <button class="btnPakai" v-b-modal="'modal-center'">
                            <h4>Tukar</h4>
                        </button>
                    </b-col>
                    <b-modal  id="modal-center" centered size="md" hide-footer hide-header>
                        <div class="container">
                            <b-row class="judulBarcode">
                                <h3>Tempat Pengambilan</h3>
                                <p style="font-weight: normal;">Pilih toko untuk melakukan pengambilan gift</p>
                            </b-row>
                            <b-row>
                                <b-form-select style="text-align: center;" v-model="tokoDipilih">
                                    <option :value="null" disabled>Pilih Toko</option>
                                    <option v-for="item in Toko" :key="item.id_department" :value="item.id_department">
                                        {{ item.department }}
                                    </option>
                                </b-form-select>
                                <!-- <b-dropdown text="Pilih Toko" variant="primary" menu-class="w-100">
                                    
                                </b-dropdown> -->
                            </b-row>
                            <!-- <b-row style="text-align: center">
                                    <barcode v-bind:value="kodeBarcode"
                                        font-size="20rem"
                                        height="70%"
                                        width=1.5
                                        format="CODE128" />
                            </b-row> -->
                            <b-row>
                                <b-col>
                                    <button class="btnTukar" @click="Tukar(gift, tokoDipilih)">Tukar</button>
                                </b-col>
                                <b-col>
                                    <button class="btnTutup" @click="$bvModal.hide('modal-center')">Tutup</button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-modal>
                </b-row>
            </div>
        </div>
        <!-- </div> -->
    </div>
</template>

<script>
// import VueBarcode from 'vue-barcode'
import axios from 'axios'
import dotenv from "dotenv"
dotenv.config()
export default {
    // components: {'barcode':VueBarcode},
    name: 'DetailTukarVoucher',
    props: ['gift'],
    created(){
        let usrdata = JSON.parse(localStorage.getItem("apiData"))
        this.userData= usrdata.data
        this.GetToko()
    },
    methods:{
        Kembali(){
            this.$router.replace('TukarPoin')
        },
        async Tukar(gift, idToko){
            try {
                //let usrDataToken = JSON.parse(localStorage.getItem("apiDataToken"))
                // eslint-disable-next-line no-unused-vars
                await axios.post(process.env.VUE_APP_tukarGift, {"gift_id": gift.id, "diambil_di": idToko, "pelanggan_id_tukar": this.userData.alias, "user_create": gift.user_create}).then(response => {
                    if (response.status == 200) {
                        this.$toast.open({
                            message:"Gift berhasil ditukar!",
                            type: "success",
                            position: "top"
                        })
                        this.$router.replace({name: 'Vouchers', params: {tab: 3}})
                    } else {
                        this.$toast.open({
                            message:"Penukaran gift gagal!",
                            type: "error",
                            position: "top"
                        })
                    }
                })
            } catch (error) {
                this.$toast.open({
                    message: error.response.data.message,
                    type: "error",
                    position: "top"
                })
            }
        },
        async GetToko() {
            let userDataToken = JSON.parse(localStorage.getItem("apiDataToken"))
            const response = await axios.get(process.env.VUE_APP_tukarToko,{ headers: {"Authorization" : `Bearer ${userDataToken.token}`} })
            var temp = response.data.data
            for(let i=0; i<temp.length; i++) {
                for(let j=0; j<this.gift.gift_department.length; j++){
                    if(temp[i].id_department == this.gift.gift_department[j].department_id){
                        this.Toko.push(temp[i])
                    }
                }
            }
        }
    },
    data(){
        return{
            userData: [],
            Toko: [],
            tokoDipilih: null
        }
    },
    mounted(){},
}
</script>

<style scoped>
.foto{
    width: 100%;
    margin-top: -2rem;
    z-index: -1;
    align-items: center;
}
.kembali{
    font-family: Oswald;
    z-index: 1;
    background-color: white;
    width: 3rem;
    border-radius: 1rem;
}
.iconKembali{
    width: 2rem;
}
.description{
    font-family: Oswald;
    margin-left: 0.5rem;
}
.periode{
    text-align: left;
}
.desc{
    text-align: left;
    font-weight: bold;
}
.btnPakai{
    font-family: Oswald;
    background-color: #C12D31;
    border-radius: 2rem;
    border: none;
    width: 100%;
    text-align: center;
    color: white;
    margin-bottom: 2rem;
    position: relative;
    bottom:0;
    height: 3rem;
}
.tokoBerlaku{
    /* margin-top: -1rem; */
    margin-bottom: 0.5rem;
}
.listToko{
    list-style-type: none;
    overflow: hidden;
    display: inline;
}
.btnTutup{
    font-family: Oswald;
    background-color: #C12D31;
    border-radius: 2rem;
    border: none;
    width: 100%;
    align-items: center;
    color: white;
    height: 2rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}
.btnTukar{
    font-family: Oswald;
    background-color: #C12D31;
    border-radius: 2rem;
    border: none;
    width: 100%;
    align-items: center;
    color: white;
    height: 2rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}
/* .vue-barcode-element{
    width: 3rem;
} */
.judulBarcode{
    font-family: Oswald;
    text-align: justify;
    font-weight: bold;
}
.snk{
    float: left;
    color: #707070;
    font-size: 1rem;
    text-align: left;
}
#setara{
    padding-top: .75rem;
    text-align: right;
}
</style>