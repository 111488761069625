import Vue from 'vue'
import VueRouter from 'vue-router'
import Overlay from './Overlay.vue'
import Login from './Login.vue'
import Homepage from './Homepage.vue'
import Register from './Register.vue'
import WelcomePage from './WelcomePage'
import Pendaftaran from './Pendaftaran'
import Riwayat from './Riwayat.vue'
import Vouchers from './Vouchers.vue'
import EditProfile from './EditProfile.vue'
import DetailGift from './DetailGift.vue'
import Toko from './Toko.vue'
import DetailPromo from './DetailPromo.vue'
import ListPromo from './ListPromo.vue'
import DetailVoucherGeneral from './DetailVoucherGeneral.vue'
import DetailVoucherSpecial from './DetailVoucherSpecial.vue'
import TukarPoin from './TukarPoin.vue'
import firebase from 'firebase/app'
import DetailTukarVoucher from './DetailTukarVoucher.vue'
import DetailTukarGift from './DetailTukarGift.vue'

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
    },
    {
        path: "/Homepage",
        name: "Homepage",
        component: Homepage,
        props: true
    },
    {
        path: "/Register",
        name: "Register",
        component: Register,
    },
    {
        path: "/WelcomePage",
        name: "WelcomePage",
        component: WelcomePage,
    },
    {
        path: "/Pendaftaran",
        name: "Pendaftaran",
        component: Pendaftaran
    },
    {
        path: "/Overlay",
        name: "Overlay",
        component: Overlay,
    },
    {
        path: "/Riwayat",
        name: "Riwayat",
        component: Riwayat,
    },
    {
        path: "/Vouchers",
        name: "Vouchers",
        component: Vouchers,
        props: true
    },
    {
        path: "/EditProfile",
        name: "EditProfile",
        component: EditProfile,
    },
    {
        path: "/DetailGift",
        name: "DetailGift",
        component: DetailGift,
        props: true
    },
    {
        path: "/Toko",
        name: "Toko",
        component: Toko,
    },
    {
        path: "/DetailPromo",
        name: "DetailPromo",
        component: DetailPromo,
        props: true

    },
    {
        path: "/ListPromo",
        name: "ListPromo",
        component: ListPromo,
        props: true
    },
    {
        path: "/DetailVoucherGeneral",
        name: "DetailVoucherGeneral",
        component: DetailVoucherGeneral,
        props: true
    },
    {
        path: "/DetailVoucherSpecial",
        name: "DetailVoucherSpecial",
        component: DetailVoucherSpecial,
        props: true
    },
    {
        path: "/TukarPoin",
        name: "TukarPoin",
        component: TukarPoin,
        props: true
    },
    {
        path: "/DetailTukarVoucher",
        name: "DetailTukarVoucher",
        component: DetailTukarVoucher,
        props: true
    },{
        path: "/DetailTukarGift",
        name: "DetailTukarGift",
        component: DetailTukarGift,
        props: true
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authRequired)) {
        if (firebase.auth().currentUser) {
        next();
        } else {
        alert('You must be logged in to see this page');
        next({
            path: '/',
        });
        }
    } else {
        next();
    }
});

export default router