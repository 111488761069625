<template>
  <div class="content">
      <b-row>
        <b-col cols="1" style="margin-left:1rem" @click="back"> <img src="@/assets/assets/BackImage.png" alt="" ></b-col>
        <b-col><h2 style="margin-right: 4rem; font-family: Oswald">Voucher & Gift</h2></b-col>
      </b-row>
      <b-card no-body id="bcard">
        <b-tabs v-model="tabIndex" card align="center" active-nav-item-class="toActive" nav-class="backgroundCol">
            <b-tab title-link-class="tab" title="Umum" id="voucherUmum">
                <div v-if="!generalVouchers.length" style="margin-top: 1rem"><h3>Tidak Ada Voucher Umum</h3></div>
                <div class="card" v-for="item in generalVouchers" :key="item.kode" @click="sendGeneralVoucher(item.kode)">
                    <div v-if="item.limit_tukar!=0">
                    <b-row>
                        <img :src="item.foto" alt="" style="max-height: 250px; object-fit:fill;">
                    </b-row>
                    <b-row>
                        <b-col cols="10">
                            <b-row style="margin-top:0.5rem;">
                                <p style="font-size: 1.25em; text-align: left; margin-left: 1rem">{{item.judul}}</p>
                            </b-row>
                            <b-row>
                                 <p style="font-size: 0.75em; text-align: left; margin-left: 1rem"> Berlaku dari: {{item.periode_awal}} s.d {{item.periode_akhir}}</p>
                            </b-row>
                        </b-col>
                        <b-col cols="2">
                            <div class="sisa" style="text-align: center; border-radius: 50%; background-color: #007476; color: white;"><p>x{{item.sisa}}</p></div>
                        </b-col>
                    </b-row>
                    
                    </div>
                </div>
            </b-tab>
            <b-tab title-link-class="tab" title="Spesial" id="voucherSpesial">
                <div v-if="!specialVouchers1.length && !specialVouchers2.length" style="margin-top: 1rem"><h3>Tidak Ada Voucher Spesial</h3></div>
                <!-- Special 1 -->
                <div class="card" v-for="item in specialVouchers1" :key="item.index" @click="sendSpecialVoucher1(item.kode)">
                    <b-row >
                        <img :src="item.foto" alt="" style="max-height: 250px; object-fit:fill;">
                    </b-row>

                    <b-row style="margin-top: 0.5rem;">
                        <b-col cols="10">
                            <b-row>
                                <p style="font-size: 1.25em; text-align: left; margin-left: 1rem">{{item.judul}}</p>
                            </b-row>
                            <b-row>
                                <p style="font-size: 0.75em; text-align: left; margin-left: 1rem"> Berlaku dari: {{item.tgl_notif}} s.d {{item.periode_akhir}}</p>
                            </b-row>
                        </b-col>
                        <b-col cols="2">
                            <div class="sisa" style="text-align: center; border-radius: 50%; background-color: #007476; color: white;"><p>x{{item.sisa}}</p></div>
                        </b-col>
                    </b-row>
                    
                </div>
                <!-- Special 2 -->
                <div class="card" v-for="item in specialVouchers2" :key="item.id" @click="sendSpecialVoucher2(item.id)">
                    <b-row v-if="item.vouchers.periode_awal&&item.vouchers.periode_akhir">
                        <img :src="item.vouchers.foto" alt="" style="max-height: 250px; object-fit:fill;">
                    </b-row>
                    <b-row v-if="item.vouchers.periode_awal&&item.vouchers.periode_akhir" style="margin-top: 0.5rem;">
                        <p style="font-size: 1.25em; text-align: left; margin-left: 1rem">{{item.vouchers.judul}}</p>
                    </b-row>
                    <b-row v-if="item.vouchers.periode_awal&&item.vouchers.periode_akhir">
                        <p style="font-size: 0.75em; text-align: left; margin-left: 1rem"> Berlaku dari: {{item.updated_at}} s.d {{item.tanggal_kadaluwarsa}}</p>
                    </b-row>
                    <b-row v-if="!item.vouchers.periode_awal&&!item.vouchers.periode_akhir">
                        <img :src="item.vouchers.foto" alt="" style="width: 100%; max-height: 200px; object-fit:cover;">
                    </b-row>
                    <b-row v-if="!item.vouchers.periode_awal&&!item.vouchers.periode_akhir" style="margin-top: 0.5rem;">
                        <p style="font-size: 1.25em; text-align: left; margin-left: 1rem">{{item.vouchers.judul}}</p>
                    </b-row>
                    <b-row v-if="!item.vouchers.periode_awal&&!item.vouchers.periode_akhir">
                        <p style="font-size: 0.75em; text-align: left; margin-left: 1rem"> Berlaku dari: {{item.updated_at}} s.d {{item.tanggal_kadaluwarsa}}</p>
                    </b-row>
                </div>
            </b-tab>
            <b-tab title-link-class="tab" title="Gift" id="gift">
                <div v-if="gifts.length == 0" style="margin-top: 1rem"><h3>Tidak Ada Gift</h3></div>
                <div class="card" v-for="item in gifts" :key="item.id" @click="sendGift(item.id)">
                    <b-row>
                        <img :src="item.gift.foto" alt="" style=" max-height: 250px; object-fit:fill;">
                    </b-row>
                    <b-row>
                        <b-col cols="10">
                            <b-row style="margin-top:0.5rem;">
                                <p style="font-size: 1.25em; text-align: left; margin-left: 1rem">{{item.gift.judul}}</p>
                            </b-row>
                            <b-row>
                                 <p style="font-size: 0.75em; text-align: left; margin-left: 1rem"> Berlaku dari: {{item.diambil_setelah}} s.d {{item.tanggal_hangus}}</p>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </b-tab>
        </b-tabs>
      </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import router from './router'
import moment from 'moment'
import dotenv from "dotenv"
import firebase from 'firebase/app'
import 'firebase/auth'
dotenv.config()

export default {
    name: "Vouchers",
    props: ["tab"],
    created() {
        if(localStorage.getItem('apiData') && localStorage.getItem('firebaseUID') && localStorage.getItem('tokenDevice') && localStorage.getItem('apiDataToken')){
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    if(firebase.auth().currentUser.emailVerified==true){
                        this.$router.replace({name : "Vouchers"}).then(() => {
                            this.$toast.open({
                                message: 'Selamat Datang Kembali !',
                                type: 'success',
                                position: 'top'
                            });
                        }).catch(()=>{})
                        this.getGeneralVouchers();
                        this.getSpecialVouchers1();
                        this.getSpecialVouchers2();
                        this.getGifts()
                        this.tabIndex = this.tab
                    }
                } else {
                    firebase.auth().signOut()
                        .then(() => {
                            localStorage.clear();
                            this.$router.replace("/").then(() => { 
                                this.$toast.open({
                                    message: 'Harap Login Kembali !',
                                    type: 'error',
                                    position: 'top'
                                });
                                this.$router.go() })
                        }).catch((err) => {
                            console.log(err);
                    });
                }
            });
        } else {
            firebase.auth().signOut()
                .then(() => {
                    localStorage.clear();
                    this.$router.replace("/").then(() => { 
                        this.$toast.open({
                            message: 'Harap Login Kembali !',
                            type: 'error',
                            position: 'top'
                        });
                        this.$router.go() })
                }).catch((err) => {
                    console.log(err);
            });
        }
    },
    data() {
        return {
            gifts: [],
            generalVouchers: [],
            specialVouchers1: [],
            specialVouchers2: [],
            tabIndex: 0
        }
    },
    methods: {
        async getGifts() {
            try {
                let usrDataToken = JSON.parse(localStorage.getItem("apiDataToken"))
                let usrData = JSON.parse(localStorage.getItem("apiData"))
                const response = await axios.get(`${process.env.VUE_APP_gifts}${usrData.data.alias}`, { headers: {"Authorization": `Bearer ${usrDataToken.token}` }, params: { diambil: 0, hangus: 0}})
                if(response.data.length >= 0) {
                    this.gifts = response.data
                }
                for(let i=0; i<this.gifts.length; i++) {
                    moment.locale("id")
                    this.gifts[i].updated_at = moment(this.gifts[i].updated_at.slice(0,13)).format('LL')
                    this.gifts[i].gift.periode_akhir = moment(this.gifts[i].gift.periode_akhir).format('LL')
                    this.gifts[i].gift.periode_awal = moment(this.gifts[i].gift.periode_awal).format('LL')
                    this.gifts[i].diambil_setelah = moment(this.gifts[i].diambil_setelah).format('LL')
                    this.gifts[i].tanggal_hangus = moment(this.gifts[i].tanggal_hangus).format('LL')
                }
               
            } catch (error) {
                console.log(error)
            }
        },
        async getGeneralVouchers() {
            try {
                let usrDataToken = JSON.parse(localStorage.getItem("apiDataToken"))
                let usrData = JSON.parse(localStorage.getItem("apiData"))
                const response = await axios.get(`${process.env.VUE_APP_generalVouchers}${usrData.data.alias}/limit/20`, { headers: {"Authorization": `Bearer ${usrDataToken.token}` }, params: { digunakan: 0, kadaluwarsa: 0}})
                this.generalVouchers = response.data.data
                for(let i=0; i<this.generalVouchers.length; i++) {
                    moment.locale("id")
                    this.generalVouchers[i].periode_awal = moment(this.generalVouchers[i].periode_awal).format('LL')
                    this.generalVouchers[i].periode_akhir = moment(this.generalVouchers[i].periode_akhir).format('LL')
                }
            } catch (error) {
                console.log(error)
            }
        },
        async getSpecialVouchers1() {
            try {
                let usrDataToken = JSON.parse(localStorage.getItem("apiDataToken"))
                let usrData = JSON.parse(localStorage.getItem("apiData"))
                const responseMember = await axios.get(`${process.env.VUE_APP_specialVouchers1}${usrData.data.alias}/limit/20`, { headers: {"Authorization": `Bearer ${usrDataToken.token}`}, params: { digunakan: 0, kadaluwarsa: 0}})
                this.specialVouchers1 = responseMember.data.data
                for(let i=0; i<this.specialVouchers1.length; i++) {
                    moment.locale("id")
                    this.specialVouchers1[i].tgl_update = moment(this.specialVouchers1[i].tgl_update.slice(0,13)).format('LL')
                    this.specialVouchers1[i].periode_akhir = moment(this.specialVouchers1[i].tgl_notif).add(10,'d').format('LL')
                    this.specialVouchers1[i].periode_awal = moment(this.specialVouchers1[i].periode_awal).format('LL')
                    this.specialVouchers1[i].tgl_notif = moment(this.specialVouchers1[i].tgl_notif).format('LL')
                }   
                
            } catch (error) {
                console.log(error)
            }
        },
        async getSpecialVouchers2() {
            try {
                let usrDataToken = JSON.parse(localStorage.getItem("apiDataToken"))
                let usrData = JSON.parse(localStorage.getItem("apiData"))
                const responseVoucher = await axios.get(`${process.env.VUE_APP_specialVouchers2}${usrData.data.alias}`, { headers: {"Authorization": `Bearer ${usrDataToken.token}`}, params: { digunakan: 0, kadaluwarsa: 0}})
                this.specialVouchers2 = responseVoucher.data
                for(let i=0; i<this.specialVouchers2.length; i++) {
                    moment.locale("id")
                    this.specialVouchers2[i].updated_at = moment(this.specialVouchers2[i].updated_at.slice(0,13)).format('LL')
                    this.specialVouchers2[i].tanggal_kadaluwarsa = moment(this.specialVouchers2[i].tanggal_kadaluwarsa).format('LL')
                    if(this.specialVouchers2[i].vouchers.periode_awal!=null&&this.specialVouchers2[i].vouchers.periode_akhir!=null) {
                        this.specialVouchers2[i].vouchers.periode_awal = moment(this.specialVouchers2[i].periode_awal).format('LL')
                        this.specialVouchers2[i].vouchers.periode_akhir = moment(this.specialVouchers2[i].periode_akhir).format('LL')
                    }
                }
                
            } catch (error) {
                console.log(error)
            }
        },
        sendGift(idGift){
            router.push({
                name:'DetailGift',
                params:{
                    items: this.gifts,
                    kodeId: idGift,
                    state: true
                }
            })
        },
        sendGeneralVoucher(kodeVoucher){
            router.push({
                name:'DetailPromo',
                params:{
                    items: this.generalVouchers,
                    kodeId: kodeVoucher,
                    state: true
                }
            })
        },
        sendSpecialVoucher1(kodeVoucher){
            router.push({
                name:'DetailPromo',
                params:{
                    items: this.specialVouchers1,
                    kodeId: kodeVoucher,
                    state: true
                }
            })
        },
        sendSpecialVoucher2(kodeVoucher){
            router.push({
                name:'DetailVoucherSpecial',
                params:{
                    items: this.specialVouchers2,
                    kodeVoucher: kodeVoucher,
                    state: true
                }
            })
        },
        back(){
            this.$router.replace('Homepage')
        }
    }
}
</script>

<style scoped>
*{
    font-family: Oswald;
}
.container {
    width: 100%;
}
b-row {
    border: 1px solid grey;
}
 .card{
        margin-bottom: 0.5rem;
       
       
    }
.bv-example-row {
    border: 1px solid lightgrey;
    
    margin-bottom: 1rem;
    padding-top: 3%;
}
#voucherTitle {
    margin-top: 1rem;
    padding: 1rem;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    text-align: center;
}
.sisa{
    margin-top: 1rem;
    margin-right: 0.5rem;
}
</style>