<template>
    <div class="container">
        <b-row>
            <b-col cols="1"><b-icon id="back" icon="arrow-left-circle" @click="kembali"></b-icon></b-col>
            <p>Sudah memiliki AMIGO MEMBER?</p>
            <b-row>
                <b-button id="sudah" class="button" variant="danger" v-b-modal.modal-center>Sudah</b-button>
                <b-modal id="modal-center" centered size="sm" hide-footer hide-header>
                    <konfirmasiIdentitas />
                </b-modal>
                <b-button id="belum" class="button" @click="belum">Belum</b-button>
            </b-row>
        </b-row>
    </div>
</template>

<script>
import konfirmasiIdentitas from './KonfirmasiIdentitas.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'


export default {
    name: 'WelcomePage',
    components: {
        konfirmasiIdentitas
    },
    data() {
        return {
            biodata: {
                data: {
                    "migrasi": null,
                    "ktp": "",
                    "nama": "",
                    "alamat": "",
                    "tempat_lahir": "",
                    "tgl_lahir": "",
                    "jns_kelamin": "",
                    "agama": "",
                    "kd_lokasi": null,
                    "id_kodepos": 0,
                    "pekerjaan": "",
                    "status_nikah": null,
                    "telp": null,
                    "ponsel": "",
                    "email": "",
                    "status": null,
                    "user_entry": "999999999",
                    "tgl_pemutihan": null,
                    "user_pemutihan": null,
                    "saldonetbon": null,
                    "poin": null,
                    "gambar": "",
                    "firebase_uid": "",
                    "token_device": ""
                }
            },
            auth: ''
        }
    },
    mounted() {
        this.$root.$on('methodBack', () => {
            this.back()
        })
    },
    created() {
        this.getToken()
        this.getAuth()
    },
    methods: {
        getAuth(){
            this.auth = false
            localStorage.setItem('auth', this.auth)
            localStorage.setItem("apiData", ".")
            localStorage.setItem("firebaseUID", ".")
            localStorage.setItem("tokenDevice", ".")
            localStorage.setItem("apiDataToken", ".")
        },
        kembali() {
            firebase.auth().signOut()
                .then(() => {
                    localStorage.clear()
                    this.$router.replace('/').then(() => { this.$router.go() })
                }).catch((err) => {
                    console.log(err);
            });
        },
        back() {
            this.$bvModal.hide('modal-center')
        },
        async getToken() {
            await firebase.auth().currentUser.getIdTokenResult().then(
                (token) => {
                    this.biodata.data.token_device = token.token;
                    console.log(token.token);
            });
        },
        belum() {   
            firebase.auth().onAuthStateChanged(
                // eslint-disable-next-line no-unused-vars
                (user) => {
                    this.biodata.data.email = user.email
                    this.biodata.data.firebase_uid = user.uid
                    localStorage.setItem("apiData", JSON.stringify(this.biodata))
                    localStorage.setItem("firebaseUID", JSON.stringify(user.uid))
                    this.$router.replace({name : "Pendaftaran"})
                }
            )
        }
    }
}
</script>

<style scoped>
    .container{
        position: relative;
        background-image: url('../assets/assets/AmigoBg.png');
        width: 100%;
        height: 100%;
        background-size: cover;
    }
    .button{
        margin: auto;
        margin-left: 3%;
        width: 100%;
    }
    .textModal{
        color: black;
    }
    #sudah{
        margin-bottom: 2%;
        border-color: white;
    }
    #belum{
        color: white;
        background: transparent;
        border-color: white;
    }
    #back{
        margin-top: 2%;
        margin-left: 2%;
        position: absolute;
        left: 0px;
        color: white;
        font-size: 2rem;
    }
    p{
        margin-top: 50%;
        color: white;
    }
</style>