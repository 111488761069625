<template>
    <div class="identitas">
        <b-row class="input">
            <h2>Konfirmasi Identitas</h2>
            <p>Masukkan Nomor Amigo Member / KTP / SIM / Kartu Pelajar</p>
            <b-form-input id="Identitas" class="input" type="text" v-model="identitas" maxlength="16" placeholder="Masukkan Nomor Identitas" required></b-form-input>
            <!-- <b-alert class="alert" v-model="stateAlert" variant="danger" dismissible>
                Nomor Identitas "{{identitas}}" Tidak Ditemukan
            </b-alert> -->
            <b-alert class="alert" v-model="stateAlert2" variant="danger" dismissible>
                Tidak boleh kosong
            </b-alert>
        </b-row>
        <b-row class="buttonInput">
            <b-col cols="6">
                <b-button id="kembali" class="button" @click="kembali">Kembali</b-button>
            </b-col>
            <b-col cols="6">
                <b-button id="konfirmasi" class="button" variant="danger" @click="confirm">Konfirmasi</b-button>
            </b-col> 
        </b-row>
    </div>
</template>

<script>
import axios from "axios"
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import dotenv from "dotenv"
dotenv.config()

export default {
    name: 'KonfirmasiIdentitas',
    data() {
        return {
            identitas:'',
            tokenUser:'',
            stateAlert: false,
            stateAlert2: false
        }
    },
    methods: {
        kembali() {
            this.$root.$emit('methodBack')
        },
        async getToken() {
            await firebase.auth().currentUser.getIdTokenResult().then(
                (token) => {
                    this.tokenUser = token.token;
                    localStorage.setItem("apiDataToken", JSON.stringify(token))
            });
        },
        konfirmasiIdentitas() {
            if(this.identitas !== ""){
                firebase.auth().onAuthStateChanged(
                    // eslint-disable-next-line no-unused-vars
                    (user) => {
                        // eslint-disable-next-line no-unused-vars
                        const res = axios.get(`${process.env.VUE_APP_konfirmasiIdentitas}${this.identitas}`, { headers: {"Authorization" : `Bearer ${this.tokenUser}`} })
                        .then((res) => {
                            if(res.status === 200){
                                localStorage.setItem("apiData", JSON.stringify(res))
                                this.$router.push({name : "EditProfile"}).catch(()=>{})
                            }
                        })
                        .catch((error) => {
                            if (error.request.status === 404) {
                                this.$toast.open({
                                    message: 'Nomor Identitas ' + this.identitas + ' Tidak Ditemukan',
                                    type: 'error',
                                    position: 'top'
                                }); 
                            }
                        })
                    }
                ); 
            }else{
                this.stateAlert2 = true
            }      
        },
        confirm() {
            this.getToken()
            this.konfirmasiIdentitas()
        }
    }
}
</script>

<style scoped>
    .identitas{
        background-image: white;
        max-width: 406px;
        height: 250px;
        min-height: 0%;
    }
    .input{
        color: black;
    }
    .buttonInput{
        margin-top: 2%;
    }
    .alert{
        background-color: transparent;
        padding: 0;
        margin: 0;
        border-style: none;
        color: red;
    }
    #konfirmasi{
        width: 100%;
    }
    #kembali{
        width: 100%;
    }
</style>