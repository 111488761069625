import Vue from 'vue'
import App from './App.vue'
import router from './components/router'
import firebase from './components/firebase'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import './registerServiceWorker'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css';
import VCalendar from 'v-calendar'

Vue.use(VCalendar, {
  componentPrefix: 'vc'
})
Vue.use(VueToast)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueGoogleMaps,{
  load: {
    key: "AIzaSyBXFBbNPZ6p2CzkY50oha-v_Cxz6jDa-KM",
    libraries: "places"
  }
})

Vue.component("modal", {
  template: "#modal-template"
});

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  firebase,
}).$mount('#app')
